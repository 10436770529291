import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Flex, Skeleton } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import clsx from 'clsx';
import Link from 'next/link';
import { useMemo, useState } from 'react';
import { P, match } from 'ts-pattern';

import {
  ActivityRightItems,
  MatchedActivityDescription,
  MatchedActivityStatus,
  type Activity,
  type ActivitySubject,
  type UserActivity,
} from '@endaoment-frontend/activities';
import { routes } from '@endaoment-frontend/routes';
import { MinusIcon, PlusIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card, EmptyStateBlock, Pill } from '@endaoment-frontend/ui/shared';
import { arrToSorted } from '@endaoment-frontend/utils';

import { ActivityExport } from './ActivityExport';
import styles from './ActivitySection.module.scss';
import { ReceiptModal } from './ReceiptModal';

const VIEW_MONTH_INCREMENT = 4;
const DONATION_ACTIVITY_TYPES: Array<UserActivity['type']> = [
  'donation',
  'fiat_donation_pledge',
  'migration_donation_pledge',
  'nec_donation_pledge',
  'stock_donation_pledge',
];

export const batchByMonth = (activities: Array<UserActivity>) => {
  const groupedByMonth = arrToSorted(activities, (a1, a2) => (a1.occurredAtUtc > a2.occurredAtUtc ? -1 : 1)).reduce(
    (acc, activity) => {
      const date = new Date(activity.occurredAtUtc);
      const month = date.getMonth();
      const monthLong = date.toLocaleString('default', { month: 'long' });
      const year = date.getFullYear();

      const key = `${year}-${month}`;

      if (!acc[key]) {
        acc[key] = { date: `${monthLong} ${year}`, activities: [] };
      }

      acc[key].activities.push(activity);

      return acc;
    },
    {} as Record<string, { date: string; activities: Array<UserActivity> }>,
  );

  return Object.entries(groupedByMonth).map(v => v[1]);
};

type ActivityFilterType = 'all' | 'donations' | 'grants' | 'portfolios' | 'rewards';

const ACTIVITY_TYPE_OPTIONS = {
  all: { value: 'all', label: 'All Activity' },
  donations: { value: 'donations', label: 'Donations' },
  grants: { value: 'grants', label: 'Grants' },
  portfolios: { value: 'portfolios', label: 'Portfolio Actions' },
  rewards: { value: 'rewards', label: 'Rewards' },
} as const satisfies Record<ActivityFilterType, { value: ActivityFilterType; label: string }>;
const DEFAULT_ACTIVITY_LIST: Array<UserActivity> = [];

const filterActivities = (activities: Array<UserActivity>, filter: ActivityFilterType): Array<UserActivity> => {
  if (filter === 'all') return activities;

  return activities.filter(activity =>
    match(filter)
      .with('grants', () => activity.type === 'internal_transfer' || activity.type === 'grant')
      .with('portfolios', () => activity.type === 'portfolio_trade')
      .with('rewards', () => activity.type === 'reward')
      .otherwise(() => DONATION_ACTIVITY_TYPES.includes(activity.type)),
  );
};

export const ActivitySection = ({
  activities = DEFAULT_ACTIVITY_LIST,
  subject,
  isLoading = false,
  className,
}: {
  activities?: Array<UserActivity>;
  subject?: ActivitySubject;
  isLoading?: boolean;
  className?: string;
}) => {
  const [receiptActivity, setReceiptActivity] = useState<Activity>();

  const [viewableMonthsOfActivity, setViewableMonths] = useState(VIEW_MONTH_INCREMENT);
  const [activityTypeFilter, setActivityTypeFilter] = useState<ActivityFilterType>('all');
  const [yearFilter, setYearFilter] = useState<number>();
  const availableActivityTypeOptions: Array<{ value: ActivityFilterType; label: string }> =
    subject === 'org'
      ? [ACTIVITY_TYPE_OPTIONS.all, ACTIVITY_TYPE_OPTIONS.donations, ACTIVITY_TYPE_OPTIONS.grants]
      : [
          ACTIVITY_TYPE_OPTIONS.all,
          ACTIVITY_TYPE_OPTIONS.donations,
          ACTIVITY_TYPE_OPTIONS.grants,
          ACTIVITY_TYPE_OPTIONS.portfolios,
          ACTIVITY_TYPE_OPTIONS.rewards,
        ];
  const shouldShowTypeOptions = activities.length > 0;

  const activityYears = arrToSorted(
    activities.reduce<Array<number>>((acc, activity) => {
      const date = new Date(activity.occurredAtUtc);
      const year = date.getFullYear();
      if (!acc.includes(year)) acc.push(year);
      return acc;
    }, []),
    (y1, y2) => y2 - y1,
  );

  const timeFilterOptions = [
    { value: undefined, label: 'All Dates' },
    ...activityYears.map(year => ({ value: year, label: year.toString() })),
  ];

  const filteredActivitiesByYear = useMemo(() => {
    const filteredByType = filterActivities(activities, activityTypeFilter);
    if (!yearFilter) return filteredByType;

    return filteredByType.filter(activity => {
      const date = new Date(activity.occurredAtUtc);
      return date.getFullYear() === yearFilter;
    });
  }, [activities, activityTypeFilter, yearFilter]);

  return (
    <>
      <ReceiptModal activity={receiptActivity} onClose={() => setReceiptActivity(undefined)} />
      <Card className={clsx(styles['container'], className)} mobileFullWidth>
        <div className={styles['title']}>
          <div>
            <h2>Activity</h2>
            <ActivityExport activities={filteredActivitiesByYear} />
          </div>
          <div>
            {timeFilterOptions.length > 2 && (
              <Select
                key='Time Filter'
                options={timeFilterOptions}
                onChange={o => setYearFilter(o?.value)}
                defaultValue={timeFilterOptions[0]}
                isDisabled={isLoading}
                isSearchable={false}
              />
            )}
            {!!shouldShowTypeOptions && (
              <Select
                key='Activity Type Filter'
                options={availableActivityTypeOptions}
                onChange={newValue => {
                  if (!newValue) return;
                  setActivityTypeFilter(newValue.value);
                }}
                defaultValue={{
                  value: activityTypeFilter,
                  label: availableActivityTypeOptions.find(option => option.value === activityTypeFilter)?.label,
                }}
                isDisabled={isLoading}
                isSearchable={false}
              />
            )}
          </div>
        </div>
        <ActivityAccordion
          isPending={isLoading}
          activities={activities}
          subject={subject}
          activityTypeFilter={activityTypeFilter}
          yearFilter={yearFilter}
          viewableMonthsOfActivity={viewableMonthsOfActivity}
          onSeeMore={() => setViewableMonths(prev => prev + VIEW_MONTH_INCREMENT)}
          onSeeLess={() => setViewableMonths(prev => prev - VIEW_MONTH_INCREMENT)}
          onClickReceipt={v => {
            if (v.type === 'transaction') return;
            setReceiptActivity(v);
          }}
        />
      </Card>
    </>
  );
};

export const ActivityAccordion = ({
  activities = [],
  subject,
  isPending,
  activityTypeFilter,
  yearFilter,
  viewableMonthsOfActivity,
  onSeeMore,
  onSeeLess,
  onClickReceipt,
}: {
  activities?: Array<UserActivity>;
  subject?: ActivitySubject;
  isPending?: boolean;
  activityTypeFilter: ActivityFilterType;
  yearFilter: number | undefined;
  viewableMonthsOfActivity: number;
  onSeeMore: () => void;
  onSeeLess: () => void;
  onClickReceipt: ((activity: UserActivity) => void) | undefined;
}) => {
  const filteredByType = filterActivities(activities, activityTypeFilter);
  const filteredActivitiesByYear = useMemo(() => {
    if (!yearFilter) return filteredByType;

    return filteredByType.filter(activity => {
      const date = new Date(activity.occurredAtUtc);
      return date.getFullYear() === yearFilter;
    });
  }, [yearFilter, filteredByType]);
  const { activitiesByMonth, totalActivityMonths } = useMemo(() => {
    const batched = batchByMonth(filteredActivitiesByYear);
    const viewableSlice = batched.slice(0, Math.min(viewableMonthsOfActivity, filteredActivitiesByYear.length));
    return {
      activitiesByMonth: viewableSlice,
      totalActivityMonths: batched.length,
    };
  }, [filteredActivitiesByYear, viewableMonthsOfActivity]);

  return match({ activitiesByMonth, isPending })
    .with({ isPending: true }, () => (
      <div className={styles['month-block']}>
        <h6>
          <Skeleton data-testid='skeleton-1' minHeight={5} w={12} />
        </h6>
        <Box as='ul' listStyleType='none'>
          <li>
            <Skeleton data-testid='skeleton-2' minHeight={8} mt='1rem' mb='0.75rem' w='100%' borderRadius='0.5rem' />
            <Skeleton data-testid='skeleton-3' minHeight={8} w='100%' borderRadius='0.5rem' />
          </li>
        </Box>
      </div>
    ))
    .with({ activitiesByMonth: P.select(P.not(P.union(P.nullish, []))) }, activitiesByMonth => (
      <>
        <Accordion allowMultiple defaultIndex={[0]}>
          {activitiesByMonth.map(byMonth => (
            <AccordionItem
              key={byMonth.date}
              className={styles['month-block']}
              border='none'
              data-testid={`activity month - ${byMonth.date}`}>
              {({ isExpanded }) => (
                <>
                  <h6 className={clsx(isExpanded && styles['expanded'])}>
                    <AccordionButton className={styles['month-button']} data-testid='accordion-button'>
                      <Flex alignItems='center' gap='0.5rem'>
                        {`${byMonth.date} `}
                        <Pill size='tiny' variation='purple'>
                          {byMonth.activities.length}
                        </Pill>
                      </Flex>
                      {isExpanded ? (
                        <MinusIcon width='1.25rem' height='1.25rem' strokeWidth={3} />
                      ) : (
                        <PlusIcon width='1.25rem' height='1.25rem' strokeWidth={3} />
                      )}
                    </AccordionButton>
                  </h6>
                  <AccordionPanel as='ul' p={0} listStyleType='none'>
                    {byMonth.activities.map((activity, index) => (
                      <li
                        key={activity.occurredAtUtc + activity.type + index}
                        className={styles['activity-item']}
                        data-testid='activity-list-item'>
                        <MatchedActivityStatus activity={activity} subject={subject} />
                        <MatchedActivityDescription activity={activity} subject={subject} />
                        <ActivityRightItems activity={activity} subject={subject} onClickReceipt={onClickReceipt} />
                      </li>
                    ))}
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
        <Flex alignItems='center' gap='0' justifyContent='center'>
          {viewableMonthsOfActivity < totalActivityMonths && (
            <Button
              className={styles['see-more']}
              filled
              variation='purple'
              size='small'
              float={false}
              onClick={() => onSeeMore?.()}>
              See More
            </Button>
          )}
          {viewableMonthsOfActivity > VIEW_MONTH_INCREMENT && (
            <Button
              className={styles['see-more']}
              variation='purple'
              size='small'
              float={false}
              onClick={() => onSeeLess?.()}>
              See Less
            </Button>
          )}
        </Flex>
      </>
    ))
    .otherwise(() => (
      <EmptyStateBlock
        variation='activity'
        title={match(activityTypeFilter)
          .with('portfolios', () => {
            return 'No recent portfolio activity';
          })
          .with('grants', () => {
            return 'No recent grant activity';
          })
          .with('donations', () => {
            return 'No recent donation activity';
          })
          .with('rewards', () => {
            return 'No recent reward activity';
          })
          .otherwise(() => {
            return 'No recent activity';
          })}
        description='Recent activity will show up here.'
        spread>
        <Button as={Link} href={routes.app.explore()} variation='org' filled size='medium'>
          Explore
        </Button>
      </EmptyStateBlock>
    ));
};
