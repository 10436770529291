import { Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';

import type { UserActivity } from '@endaoment-frontend/activities';
import { useAccountDisplayName } from '@endaoment-frontend/authentication';
import { useIsMounted } from '@endaoment-frontend/hooks';
import { LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button } from '@endaoment-frontend/ui/shared';

import styles from './ActivityExport.module.scss';
import { getActivitiesToExport } from './getActivitiesToExport';

const DEFAULT_ACTIVITY_LIST: Array<UserActivity> = [];

export const ActivityExport = ({ activities = DEFAULT_ACTIVITY_LIST }: { activities: Array<UserActivity> }) => {
  const isMounted = useIsMounted();

  const contentRef = useRef<HTMLDivElement>(null);
  const print = useReactToPrint({ content: () => contentRef.current, documentTitle: 'Endaoment Activity' });
  const [display, setDisplay] = useState(false);

  const exportable = getActivitiesToExport(activities);

  const { accountName } = useAccountDisplayName();

  // Adding simple workaround to simplify ActivitySection tests - RTL visibility checks would pick up the hidden elements
  // if using purely style based hide
  const handlePrint = () => {
    setDisplay(true);
    setTimeout(print, 500);
  };

  if (activities.length === 0 || !isMounted) return null;

  return (
    <div className={styles.container}>
      <Popover placement='top'>
        <PopoverTrigger>
          <Button filled faded size='small' variation='org' float={false} className={styles.button}>
            Export
          </Button>
        </PopoverTrigger>
        <PopoverContent className={styles.exports}>
          <PopoverArrow />
          <PopoverBody className={styles.buttons}>
            <button onClick={handlePrint} type='button'>
              PDF
            </button>
            <CSVLink data={exportable} filename='endaoment-activity.csv'>
              CSV
            </CSVLink>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      {/* See justification for this above */}
      {!!display && (
        <div style={{ display: 'none' }}>
          <div ref={contentRef}>
            <header className={styles.header}>
              <div>
                <LogoIcon />
                <h4>Endaoment Activity</h4>
              </div>
              <span>{`Prepared for: ${accountName}`}</span>
            </header>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Operation</th>
                  <th>Status</th>
                  <th>Destination</th>
                  <th>USD</th>
                  <th>Asset</th>
                  <th>Amount</th>
                  <th>Tx URL</th>
                </tr>
              </thead>
              <tbody>
                {exportable.map(row => {
                  return (
                    <tr key={row.date + row.operation + row.usd} className={styles.row}>
                      <td>{row.date}</td>
                      <td>{row.operation}</td>
                      <td>{row.status}</td>
                      <td>{row.destination}</td>
                      <td>{row.usd}</td>
                      <td>{row.asset}</td>
                      <td>{row.assetAmount}</td>
                      <td>{!!row.transactionUrl && <a href={row.transactionUrl}>Link</a>}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
